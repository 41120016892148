import React from "react"
import RootElement from "./src/components/root-element"
// import { Helmet } from "react-helmet"
import "@szhsin/react-menu/dist/index.css"
import "@szhsin/react-menu/dist/transitions/slide.css"
import { Script } from "gatsby"

export const wrapRootElement = ({ element }) => {
  return (
    <>
      <Script
        key={"setup-consent-mode"}
        dangerouslySetInnerHTML={{
          __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('consent', 'default',
          {
          'ad_storage': 'denied',
          'analytics_storage': 'denied',
          'ad_personalization': 'denied',
          'ad_user_data': 'denied',
          'personalization_storage': 'denied',
          'security_storage': 'granted',
          'functionality_storage': 'denied',
          });
        `,
        }}
      ></Script>
      <Script
        src="https://consent.cookiebot.com/uc.js"
        data-cbid="1d920cb8-d500-4561-b66a-20493fe63aff"
        data-blockingmode="auto"
        type="text/javascript"
        id="Cookiebot"
        async
      />

      {/* <Helmet>  
      </Helmet> */}
      <RootElement>{element}</RootElement>
    </>
  )
}

export const onServiceWorkerUpdateReady = () => {
  window.location.reload()
}
